export const logError = async (error, additionalInfo = {}) => {
    try {
        const errorData = {
            error: error.message || String(error),
            url: window.location.href,
            timestamp: new Date().toISOString(),
            info: additionalInfo
        };

        const response = await fetch('http://127.0.0.1:5000/log', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(errorData)
        });

        if (!response.ok) {
            console.error('Failed to log error to server:', await response.text());
        }
    } catch (e) {
        // If logging fails, at least log to console
        console.error('Failed to send error to logging server:', e);
    }
}; 