import React from 'react';
import { Link } from 'react-router-dom';

function UnsubscribeSuccessPage() {
  return (
    <div className="container">
      <h1>Uspešno ste se odjavili</h1>
      <p>Nećete više dobijati obaveštenja o parking kaznama.</p>
      <Link to="/" className="button">Nazad na početnu</Link>
    </div>
  );
}

export default UnsubscribeSuccessPage; 