import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SubscriptionForm from './SubscriptionForm';
import UnsubscribePage from './UnsubscribePage';
import SubscriptionSuccessPage from './SubscriptionSuccessPage';
import SubscriptionErrorPage from './SubscriptionErrorPage';
import UnsubscribeSuccessPage from './UnsubscribeSuccessPage';
import UnsubscribeErrorPage from './UnsubscribeErrorPage';
import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SubscriptionForm />} />
        <Route path="/unsubscribe" element={<UnsubscribePage />} />
        <Route path="/subscription-success" element={<SubscriptionSuccessPage />} />
        <Route path="/subscription-error" element={<SubscriptionErrorPage />} />
        <Route path="/unsubscribe-success" element={<UnsubscribeSuccessPage />} />
        <Route path="/unsubscribe-error" element={<UnsubscribeErrorPage />} />
      </Routes>
    </Router>
  );
}

export default App;
