import { useState } from 'react';
import { logError } from './errorLogger';
import './SubscriptionForm.css';

function SubscriptionForm() {
  const [licensePlate, setLicensePlate] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [status, setStatus] = useState('idle');

  const validateForm = () => {
    const plateRegex = /^[A-ZČĆŠĐŽ]{2}[0-9]{3,4}[A-ZČĆŠĐŽ]{2}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!plateRegex.test(licensePlate)) {
      setError('Unesite ispravnu registarsku oznaku (npr. BG1234CD)');
      return false;
    }

    if (!emailRegex.test(email)) {
      setError('Unesite ispravnu email adresu');
      return false;
    }

    setError('');
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) return;

    setStatus('submitting');

    try {
      const response = await fetch('https://driveglobally.com/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ licensePlate, email }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Pretplata nije uspela');
      }

      setStatus('success');
      setLicensePlate('');
      setError('');
      
    } catch (err) {
      setStatus('error');
      setError('Pretplata nije uspela. Pokušajte ponovo.');
      
      // Log the error to backend
      logError(err, {
        component: 'SubscriptionForm',
        operation: 'handleSubmit',
        stackTrace: err.stack,
        errorMessage: err.message,
        responseStatus: err.response?.status
      });
    }
  };

  const isSubmitting = status === 'submitting';
  const isSuccess = status === 'success';

  if (isSuccess) {
    return (
      <div className="page-container">
        <div className="form-container success-container">
          <h1>Proverite svoj email</h1>
          <p>Poslali smo vam email sa linkom za potvrdu pretplate na adresu:</p>
          <p className="email-highlight">{email}</p>
          <p>Molimo vas da kliknete na link u emailu kako biste potvrdili pretplatu.</p>
          <button 
            onClick={() => {
              setStatus('idle');
              setEmail('');
              setLicensePlate('');
            }}
            className="new-subscription-button"
          >
            Nova pretplata
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="page-container">
      <div className="form-container">
        <h1>Budite obavesteni o parking kaznama</h1>
        <h2>Ustedite 50% placanjem u prvih 7 dana</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="licensePlate">Registarska oznaka:</label>
            <input
              type="text"
              id="licensePlate"
              value={licensePlate}
              onChange={(e) => setLicensePlate(e.target.value.toUpperCase())}
              placeholder="BG1234CD"
              disabled={isSubmitting}
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="email">Email adresa:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="petar@gmail.com"
              disabled={isSubmitting}
            />
          </div>

          {error && <div className="error-message">{error}</div>}
          
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Slanje...' : 'Pretplati se'}
          </button>
        </form>
      </div>
    </div>
  );
}

export default SubscriptionForm; 