import React from 'react';
import { useSearchParams } from 'react-router-dom';
import './App.css';

function UnsubscribePage() {
  const [searchParams] = useSearchParams();
  const plate = searchParams.get('plate');

  return (
    <div className="App">
      <div className="form-container">
        {plate ? (
          <>
            <h1>Uspešno ste se odjavili</h1>
            <p>
              Nećete više dobijati obaveštenja o kaznama za registarsku oznaku:{' '}
              <strong>{plate}</strong>
            </p>
          </>
        ) : (
          <>
            <h1>Greška prilikom odjave</h1>
            <p>Došlo je do greške prilikom obrade vašeg zahteva. Molimo pokušajte ponovo.</p>
          </>
        )}
      </div>
    </div>
  );
}

export default UnsubscribePage; 