import React from 'react';
import { useSearchParams } from 'react-router-dom';
import './App.css';

function SubscriptionSuccessPage() {
  const [searchParams] = useSearchParams();
  const plate = searchParams.get('plate');

  return (
    <div className="App">
      <div className="form-container">
        {plate ? (
          <>
            <h1>Uspešno ste potvrdili pretplatu</h1>
            <p>
              Primaćete obaveštenja o kaznama za registarsku oznaku:{' '}
              <strong>{plate}</strong>
            </p>
            <p>
              Obavestićemo Vas čim dobijemo informaciju o novoj kazni za ovo vozilo.
            </p>
          </>
        ) : (
          <>
            <h1>Greška prilikom potvrde pretplate</h1>
            <p>Došlo je do greške prilikom obrade vašeg zahteva. Molimo pokušajte ponovo.</p>
          </>
        )}
      </div>
    </div>
  );
}

export default SubscriptionSuccessPage; 