import React from 'react';
import { Link } from 'react-router-dom';

function SubscriptionErrorPage() {
  return (
    <div className="container">
      <h1>Greška pri pretplati</h1>
      <p>Došlo je do greške prilikom pretplate. Molimo vas da pokušate ponovo ili nas kontaktirajte ako se problem nastavi.</p>
      <Link to="/" className="button">Nazad na početnu</Link>
    </div>
  );
}

export default SubscriptionErrorPage; 