import React from 'react';
import { Link } from 'react-router-dom';

function UnsubscribeErrorPage() {
  return (
    <div className="container">
      <h1>Greška pri odjavljivanju</h1>
      <p>Došlo je do greške prilikom odjavljivanja. Molimo vas da pokušate ponovo ili nas kontaktirajte ako se problem nastavi.</p>
      <Link to="/" className="button">Nazad na početnu</Link>
    </div>
  );
}

export default UnsubscribeErrorPage; 